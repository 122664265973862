.element {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.element .div {
  width: 1920px;
  height: 6220px;
  position: relative;
}

.element .intro {
  background-color: #fff;
  width: 1920px;
  height: 900px;
  position: absolute;
  top: 0;
  left: -6px;
}

.element .personal-care {
  color: #2f2f2f;
  letter-spacing: -.39px;
  height: 34px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 70px;
  left: 764px;
}

.element .text-wrapper {
  color: #2f2f2f;
  letter-spacing: -.39px;
  height: 34px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 70px;
  left: 1690px;
}

.element .image {
  width: 939px;
  height: 742px;
  position: absolute;
  top: 160px;
  left: 986px;
}

.element .group {
  width: 714px;
  height: 490px;
  position: absolute;
  top: 230px;
  left: 125px;
}

.element .context {
  width: 701px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-2 {
  color: #2f2f2f;
  letter-spacing: -2.39px;
  white-space: nowrap;
  height: 86px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 80px;
  font-weight: 700;
  line-height: 86.4px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .p {
  color: #777;
  letter-spacing: -.29px;
  width: 697px;
  height: 216px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 118px;
  left: 0;
}

.element .scope-of-work {
  width: 714px;
  height: 120px;
  position: absolute;
  top: 370px;
  left: 2px;
}

.element .overlap {
  width: 451px;
  height: 156px;
  position: absolute;
  top: 0;
  left: -32px;
}

.element .UIX {
  width: 287px;
  height: 120px;
  position: absolute;
  top: 36px;
  left: 0;
}

.element .SM {
  width: 180px;
  height: 56px;
  position: absolute;
  top: 64px;
  left: 271px;
}

.element .overlap-group {
  background-color: #614ad3;
  border-radius: 28px;
  width: 178px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-3 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  height: 33px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  left: 65px;
}

.element .overlap-group-wrapper {
  background-color: #fff;
  border-radius: 23.06px;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 5px;
  left: 6px;
}

.element .overlap-group-2 {
  width: 21px;
  height: 21px;
  position: relative;
  top: 12px;
  left: 12px;
}

.element .line {
  width: 5px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 8px;
}

.element .img {
  width: 21px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.element .text-wrapper-4 {
  color: #f7847e;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 32px;
}

.element .WD {
  width: 281px;
  height: 56px;
  position: absolute;
  top: 64px;
  left: 433px;
}

.element .overlap-2 {
  background-color: #2934d0;
  border-radius: 28px;
  width: 279px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-5 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  height: 33px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  left: 64px;
}

.element .logo {
  width: 115px;
  height: 33px;
  position: absolute;
  top: 72px;
  left: 125px;
}

.element .website {
  width: 1920px;
  height: 4780px;
  position: absolute;
  top: 900px;
  left: 0;
}

.element .outro {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 5680px;
  left: 0;
}

.element .div-wrapper {
  background-image: url("rectangle-122.69e8095d.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 540px;
  position: relative;
}

.element .logo-wrapper {
  background-image: url("mask-group.5de7c212.png");
  background-size: 100% 100%;
  height: 540px;
  position: relative;
}

.element .logo-2 {
  width: 144px;
  height: 32px;
  position: absolute;
  top: 391px;
  left: 1657px;
}

/*# sourceMappingURL=index.9c8778ef.css.map */
